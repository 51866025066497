import React from 'react';
import '../styles/resume.css'; // Assuming you have a separate CSS file for styling



function Resume(){

    return(
        <section id="resume" class="resume section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Resume</h2>
            <p>As a dedicated Mobile Application and Web Developer with a degree in Information and Communication Technology from the University of Colombo, I have a proven track record of delivering high-quality software solutions. My expertise spans multiple development areas, including front-end and back-end development, mobile application design, and web technologies. 
    With a focus on creating seamless user experiences, I continuously strive to stay updated with the latest industry trends and best practices, ensuring that I deliver innovative and efficient solutions tailored to clients' needs.</p>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <h3 class="resume-title">Sumary</h3>
                <div class="resume-item pb-0">
                  <h4>Sehan Hansaja Gamage</h4>
                  <p><em>Passionate and results-oriented Mobile Application and Web Developer with expertise in creating user-centered digital solutions. Currently pursuing a Bachelor's degree in Information and Communication Technology, with hands-on experience in developing innovative applications that enhance user experiences and streamline operations.</em></p>
                  <ul>
                    <li>NO 138, Gurulana, Bope, Padukka, Sri Lanka</li>
                    <li>+94 70 287 6795</li>
                    <li>sehangamage708@gmail.com</li>
                  </ul>
                </div>
    
                <h3 class="resume-title">Education</h3>
                <div class="resume-item">
                  <h4>Master of Fine Arts &amp; Graphic Design</h4>
                  <h5>2015 - 2016</h5>
                  <p><em>Rochester Institute of Technology, Rochester, NY</em></p>
                  <p>Qui deserunt veniam. Et sed aliquam labore tempore sed quisquam iusto autem sit. Ea vero voluptatum qui ut dignissimos deleniti nerada porti sand markend</p>
                </div>
    
                <div class="resume-item">
                  <h4>Bachelor of Fine Arts &amp; Graphic Design</h4>
                  <h5>2010 - 2014</h5>
                  <p><em>Rochester Institute of Technology, Rochester, NY</em></p>
                  <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila</p>
                </div>
    
              </div>
    
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="resume-title">Professional Experience</h3>
                <div class="resume-item">
                  <h4>Senior graphic design specialist</h4>
                  <h5>2019 - Present</h5>
                  <p><em>Experion, New York, NY </em></p>
                  <ul>
                    <li>Lead in the design, development, and implementation of the graphic, layout, and production communication materials</li>
                    <li>Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project. </li>
                    <li>Supervise the assessment of all graphic materials in order to ensure quality and accuracy of the design</li>
                    <li>Oversee the efficient use of production project budgets ranging from $2,000 - $25,000</li>
                  </ul>
                </div>
    
                <div class="resume-item">
                  <h4>Graphic design specialist</h4>
                  <h5>2017 - 2018</h5>
                  <p><em>Stepping Stone Advertising, New York, NY</em></p>
                  <ul>
                    <li>Developed numerous marketing programs (logos, brochures,infographics, presentations, and advertisements).</li>
                    <li>Managed up to 5 projects or tasks at a given time while under pressure</li>
                    <li>Recommended and consulted with clients on the most appropriate graphic design</li>
                    <li>Created 4+ design presentations and proposals a month for clients and account managers</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
}


export default Resume;