import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/resume.css';

function Projects() {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the project data from the backend
    fetch('https://reactserver.com.sehandeveloper.com/projects')
      .then(response => response.json())
      .then(data => setProjects(data))
      .catch(error => console.error('Error fetching project data:', error));
  }, []);

  const handleRedirect = (id) => {
    navigate(`/projects/details/${id}`);
  };

  return (
    <section id="projects" className="projects section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Projects</h2>
        <p>Throughout my Univercity Life, I have worked on a variety of exciting and challenging projects, ranging from mobile applications to complex web platforms. Each project showcases my ability to design, develop, and implement solutions that not only meet client requirements but also enhance user experience and functionality. From seamless user interfaces to robust backend systems, my portfolio reflects a commitment to quality and innovation in the tech space.
        </p>
      </div>
      <div className="container mt-4">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {projects.map((project) => (
            <div className="col" key={project.id}>
              <div className="card h-100">
                <img
                  src={`https://reactserver.com.sehandeveloper.com/${project.image_url}`}
                  className="card-img-top"
                  alt={project.title}
                />
                <div className="card-body">
                  <h5 className="card-title text-left"><b>{project.title}</b></h5>
                  <p className="card-text">{project.descriptionTitle}</p>
                  <button className="btn btn-primary" onClick={() => handleRedirect(project.id)}>More Details</button>
                </div>
                <div className="card-footer">
                  {/* <small className="text-muted">Last updated {new Date(project.last_updated).toLocaleString()}</small> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
