import React from 'react';
import '../styles/footer.css';


function Footer(){

    return(
    <footer id="footer" className="footer position-relative">
        <div className="container">
          <h3 className="sitename">SEHAN HANSAJA GAMAGE</h3>
          {/* <p>Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni eligendi fuga maxime saepe commodi placeat.</p> */}
          <div className="social-links d-flex justify-content-center">
            
            <a href=""><i className="bi bi-facebook"></i></a>
            <a href=""><i className="bi bi-instagram"></i></a>
            <a href=""><i className="bi bi-linkedin"></i></a>
          </div>
          <div className="container">
            <div className="copyright">
              <span>Copyright</span> <strong className="px-1 sitename">Sehan Hansaja Gamage</strong> <span>All Rights Reserved</span>
            </div>
            <div className="credits">
              Designed by <a href="https://www.sehandeveloper.com/">SH Technology</a>
            </div>
          </div>
        </div>
    </footer>
    );
}

export default Footer;