import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/project_del.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function PortfolioDetails() {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://reactserver.com.sehandeveloper.com/projects/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setProject(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching project data:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!project) {
    return <div>Project not found.</div>;
  }

  return (
    <div>
      <div className="page-title">
        <div className="container">
          <h1>{project.title}</h1>
        </div>
      </div>

      <section id="portfolio-details" className="portfolio-details">
        <div className="container">
          <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000" style={{ width:'100%' }}>
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={`https://reactserver.com.sehandeveloper.com/${project.image_url}`} className="d-block w-100" alt="..." style={{ height: '600px', objectFit: 'contain',border:'1px solid black' }} />
                <div className="carousel-caption d-none d-md-block">
                  <h5>First slide label</h5>
                  <p>Some representative placeholder content for the first slide.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={`https://reactserver.com.sehandeveloper.com/${project.image_url1}`} className="d-block w-100" alt="..." style={{ height: '600px', objectFit: 'contain',border:'1px solid black' }}/>
                <div className="carousel-caption d-none d-md-block">
                  <h5>Second slide label</h5>
                  <p>Some representative placeholder content for the second slide.</p>
                </div>
              </div>
              <div className="carousel-item">
                <img src={`https://reactserver.com.sehandeveloper.com/${project.image_url2}`} className="d-block w-100" alt="..." style={{ height: '600px', objectFit: 'contain',border:'1px solid black' }}/>
                <div className="carousel-caption d-none d-md-block">
                  <h5>Third slide label</h5>
                  <p>Some representative placeholder content for the third slide.</p>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

          {/* Description and Project Info */}
          <div className="row justify-content-between gy-4 mt-4">
            <div className="col-lg-8">
              <div className="portfolio-description">
                <h2>{project.descriptionTitle}</h2>
                <p>{project.description}</p>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="portfolio-info">
                <h3>Project Information</h3>
                <ul>
                  <li><strong>Category</strong>: {project.category}</li>
                  <li><strong>Client</strong>: {project.client}</li>
                  <li><strong>Project date</strong>: {new Date(project.project_date).toLocaleDateString()}</li>
                  <li><strong>Technology</strong>: {project.technology}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PortfolioDetails;
