import React from 'react';
import Swal from 'sweetalert2';
import '../styles/contact.css'; 

function ContactForm() {
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const form = event.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries()); // Convert FormData to plain object

    try {
      const response = await fetch('https://reactserver.com.sehandeveloper.com/user/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: result.message,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        form.reset(); // Clear the form fields
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'There was a problem sending your message. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'There was a problem sending your message. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p>We’d love to hear from you! Whether you have a question, feedback, or just want to connect, feel free to reach out.</p>
      </div>

      <div className="container" data-aos="fade" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-4">
            <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
              <i class="bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h3>Address</h3>
                <p>NO 138, Gurulana, Bope, Padukka.</p>
              </div>
            </div> 

            <div className="info-item d-flex">
              <i className="bi bi-telephone"></i>
              <div>
                <h3>Call Us</h3>
                <p>+94 70 2876 795</p>
              </div>
            </div>

            <div className="info-item d-flex">
              <i className="bi bi-envelope"></i>
              <div>
                <h3>Email Us</h3>
                <p>sehangamage708@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <form className="php-email-form" onSubmit={handleSubmit}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <input type="text" name="name" className="form-control" placeholder="Your Name" required />
                </div>

                <div className="col-md-6">
                  <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                </div>

                <div className="col-md-12">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" required />
                </div>

                <div className="col-md-12">
                  <textarea className="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <button type="submit">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
