import React, { useEffect } from 'react';
import AOS from 'aos';
import Typed from 'typed.js';
import 'aos/dist/aos.css';

function Typing_component(){

    useEffect(() => {
        // Initialize AOS
        AOS.init();
    
        // Initialize Typed.js
        const typed = new Typed('.typed', {
          strings: ['Designer', 'Developer', 'Mobile Application Developer', 'Photographer'],
          typeSpeed: 50,
          backSpeed: 50,
          loop: true
        });
    
        // Cleanup function to destroy Typed instance
        return () => {
          typed.destroy();
        };
    }, []);
    return(
      <div className="center-content" data-aos="zoom-out">
        <div className="row postion">
          <div className="col-lg-12">
            <h2 className='main-text hero-text'><b>I'M SHEHAN HANSAJA GAMAGE.</b></h2>
            <p>I'm <span className="typed"><u>Web Developer</u></span><span className="typed-cursor typed-cursor--blink" aria-hidden="true"></span></p>
            <div className="social-links">
              <a href="#"><i className="bi bi-twitter icon-so"></i></a>
              <a href="#"><i className="bi bi-facebook icon-so"></i></a>
              <a href="#"><i className="bi bi-instagram icon-so"></i></a>
              <a href="#"><i className="bi bi-linkedin icon-so"></i></a>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Typing_component;