import React from 'react';
import '../styles/resume.css';
import Profile from '../assets/Logo.png';

function Testomotion() {
    return (
        <section id="testimonials" className="testimonials section">
            <div className="container section-title" data-aos="fade-up">
                <h2>Testimonials</h2>
                <p>Hear from the clients and colleagues I've worked with. Their feedback reflects my dedication to delivering exceptional results and my ability to collaborate effectively in dynamic environments. I’m proud of the lasting relationships built through mutual respect and trust.
                </p>
            </div>

            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="swiper init-swiper">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="testimonial-item">
                                <div className="row gy-4 justify-content-center">
                                    <div className="col-lg-6">
                                        <div className="testimonial-content">
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                <span>We worked with MR. Sehan Gamage on a Report Management System for Mercedes-Benz Sri Lanka, and the results were outstanding. Their expertise in Laravel significantly improved our operational efficiency and data accuracy. The system allows us to generate reports seamlessly and access critical data in real-time, streamlining our decision-making processes. Highly recommended!</span>
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                            <h3>DUSHAN ISURU</h3>
                                            <h4>Executive Forman in Final Control Department Mercedes-Benz</h4>
                                            <div className="stars">
                                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 text-center">
                                        <img src={'https://media.licdn.com/dms/image/v2/D5603AQHa6nndWCBbXg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1700660469502?e=1732147200&v=beta&t=pYJUwbz_uXO3pCjYWZB7qaLR1DJEpMUNeuZVNVEf8YE'} className="img-fluid testimonial-img" alt="Profile of Saul Goodman" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Repeat similar blocks for other testimonials */}

                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    );
}

export default Testomotion;
