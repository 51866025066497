import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/Logo.png';
import '../App.css';

function NavbarComponent({ activeSection }) {
  return (
    <Navbar expand="lg" className="bg-body sticky-top">
      <Container fluid>
        <Navbar.Brand href="/#" className='nav-name'>
          <img src={Logo} className='logo' alt="logo"/>Technology
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto" style={{ maxHeight: '100px' }} navbarScroll>
            {/* This Nav component is used to align the following Nav to the right */}
          </Nav>
          <Nav className="ms-auto" style={{ maxHeight: '100px' }} navbarScroll>
            <Nav.Link 
              href="/#home" 
              className={`mx-2 ${activeSection === 'home' ? 'active' : ''}`}
            >
              Home
            </Nav.Link>
            <Nav.Link 
              href="/#about" 
              className={`mx-2 ${activeSection === 'about' ? 'active' : ''}`}
            >
              About
            </Nav.Link>
            <Nav.Link 
              href="/#resume" 
              className={`mx-2 ${activeSection === 'resume' ? 'active' : ''}`}
            >
              Resume
            </Nav.Link>
            <Nav.Link 
              href="/#projects" 
              className={`mx-2 ${activeSection === 'projects' ? 'active' : ''}`}
            >
              Projects
            </Nav.Link> 

            <Nav.Link 
              href="/#testimonials" 
              className={`mx-2 ${activeSection === 'testimonials' ? 'active' : ''}`}
            >
              Testimonials
            </Nav.Link>

            <Nav.Link 
              href="/#contact" 
              className={`mx-2 ${activeSection === 'contact' ? 'active' : ''}`}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
