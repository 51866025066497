import React from 'react';
import profileImage from '../assets/Logo.png';
/* import '../styles/about.css' */

const About = () => {
  return (
    <section id="about" className="about section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>About</h2>
        <p>
        I am a passionate Mobile Application and Web Developer with expertise in creating seamless and responsive user experiences. 
          As a student at the University of Colombo with a degree in Information and Communication Technology, I specialize in building innovative applications 
          that solve real-world problems and enhance business operations.        </p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
      <div className="row gy-4 justify-content-center d-flex align-items-center">
  <div className="col-lg-4 d-flex justify-content-center">
    <img src={profileImage} className="img-fluid" alt="Profile" />
  </div>
  <div className="col-lg-8 d-flex flex-column">
    <h2>Mobile Application &amp; Web Developer.</h2>
    {/* <p className="fst-italic py-3">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    </p> */}
    <div className="row">
      <div className="col-lg-6">
        <ul>
          <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>29 November 2001</span></li>
          <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>www.sehandeveloper.com</span></li>
          <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+94 70 287 6795</span></li>
          <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>NO 138, Gurulana, Bope, Padukka.</span></li>
        </ul>
      </div>
      <div className="col-lg-6">
        <ul>
          <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>22</span></li>
          <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Bachelor of Information and Communication Technology</span></li>
          <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>sehangamage708@gmail.com</span></li>
        </ul>
      </div>
    </div>
    <p className="py-3">
    My journey as a developer has been driven by a deep passion for technology and innovation. I enjoy tackling complex problems, whether it's building mobile applications or designing web experiences, and transforming them into simple, user-friendly solutions. 
  I believe that technology can greatly enhance lives and businesses, and I am committed to leveraging my skills to create impactful and efficient digital solutions. 
  Collaboration, creativity, and continuous learning are at the core of my work, and I look forward to growing further in this dynamic field.
    </p>
  </div>
</div>

      </div>
    </section>
  );
};

export default About;
