import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'; // Import your global styles
import NavbarComponent from './components/navbar'; // Adjust the path as per your directory structure
import TypingComponent from './components/Type_Component';
import Testomotial from './components/testomotioal';
import Resume from './components/resume';
import About from './components/about';
import Contact from './components/contact';
import Footer from './components/footer';
import ParticlesComponent from './components/ParticlesBackground';
import Project from './components/projects'; // The component that lists the projects
import ProjectDetails from './components/Project_Details'; // Component to show project details
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';

function App() {
  const [activeSection, setActiveSection] = useState('home');
  const [showParticles, setShowParticles] = useState(true);

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.target.id === 'home') {
            setShowParticles(entry.isIntersecting);
          }
          if (entry.isIntersecting) {
            setActiveSection(entry.target.getAttribute('id'));
          }
        });
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    sections.forEach(section => observer.observe(section));

    // Cleanup on unmount
    return () => sections.forEach(section => observer.unobserve(section));
  }, []);

  return (
    <Router>
      <div className="app">
        {/* Navbar at the top */}
        <NavbarComponent activeSection={activeSection} />

        {/* Define routes for each section */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                {/* Typing animation and intro section */}
                <section className="hero-section" id="home">
                  {showParticles && <ParticlesComponent id="particles" />}
                  <div className="content">
                    <TypingComponent />
                  </div>
                </section>

                <section id='about'>
                  <About />
                </section>

                <section id="resume">
                  <Resume />
                </section>

                <section id="projects">
                  <Project />
                </section>

                <section id="testimonials">
                  <Testomotial />
                </section>

                <section id="contact">
                  <Contact />
                </section>

                <div>
                  <Footer />
                </div>
              </>
            }
          />
          {/* Add the route for the project details page */}
          <Route path="/projects/details/:id" element={<ProjectDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
